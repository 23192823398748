import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "AskUdhay"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" keywords={[`blog`, `meetup`, `angular`, `raleigh`]} />
        <div style={{ backgroundColor: "white", padding: "1px 10px 5px 10px" }}>
          <h2>
            Hello{" "}
            <span role="img" aria-label="wave emoji">
              👋
            </span>
          </h2>
          <p>Welcome to my blog !!</p>
          <p>
            I am{" "}
            <Link
              style={{ boxShadow: `none` }}
              to={`/blog/hello-dev-community/`}
            >
              Udhay
            </Link>
            , a seasoned Full stack java developer, have close to 12 years of
            experience in developing different software systems (both frontend
            and backend).
          </p>
          <p>
            I love coding, writing, tech talks, teaching technical stuffs,
            hosting Meetups events and acrylic painting on Canvas. I live and
            work in Raleigh, USA building amazing things.
          </p>
          <p>
            I love to keep things simple and minimal, that's reflected on this
            site too.
          </p>
          <p>
            You can find me on Twitter -{" "}
            <a href="https://twitter.com/askudhay">@AskUdhay</a>{" "}
            <span role="img" aria-label="heart emoji">
              ❤️
            </span>
          </p>
          <h3>
            Recent Talks{" "}
            <span role="img" aria-label="microphone emoji">
              🎤
            </span>
          </h3>
          <div>
            <div className="pClass">
              <ul>
                <li>
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`talk/Angular-Hive-November-2020/`}
                  >
                    Let's create an Angular Story book!
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`talk/RTP-Angular-August-2020/`}
                  >
                    Angular + Docker = Portable Angular DEV Environment
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`talk/Angular-Hive-August-2020/`}
                  >
                    Angular + Docker = Portable Angular DEV Environment
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`talk/GDG-Indy-June-2020`}
                  >
                    GDG Indy - PWA is the future
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`talk/ngKeralam-June-2020/`}
                  >
                    ngKeralam - Preloading strategies for Angular Modules
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`talk/Future-Tech-Webinar-May-2020/`}
                  >
                    Futuer Tech Webinar - JavaScript Zero to Hero
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`/talk/Angular-RTP-Meetup-May-2020/`}
                  >
                    Preloading strategies for Angular Modules
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`/talk/Angular-Raleigh-Meetup-April-2020/`}
                  >
                    PWA is the future - Let's develop an Angular PWA App
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`/talk/Angular-Raleigh-Meetup-March-2020/`}
                  >
                    Build, Test & Deploy Angular app to Google Cloud using
                    GitLab
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`/talk/Modern-Web-Meetup-February-2020/`}
                  >
                    Modern Web - February Event - PWA + Angular
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`/talk/Angular-Raleigh-Meetup-February-2020/`}
                  >
                    Deep dive into Routing - Develop Cookbook Recipe App
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`/talk/Angular-Raleigh-Meetup-January-2020/`}
                  >
                    Get Started with Angular 8 - Say hello to Angular!
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <h3>
            Upcoming Meetup Events{" "}
            <span role="img" aria-label="raising hand emoji">
              🙋
            </span>
          </h3>
          <div>
            <div className="pClass">
              <ul>
                <li>
                  <a
                    style={{ boxShadow: `none` }}
                    target="_blank"
                    href="https://www.meetup.com/RTP-Angular/events/274384587/"
                    rel="noopener noreferrer"
                  >
                    Angular User Authentication with Auth0
                  </a>
                  &nbsp; - Online
                </li>
              </ul>
            </div>
            <Link style={{ boxShadow: `none` }} to={`/meetup-events`}>
              ✴️ Past Meetup Events ✴️
            </Link>
          </div>
          <h3>
            Hand picked articles for you{" "}
            <span role="img" aria-label="document emoji">
              📝
            </span>
          </h3>
          <div>
            <div className="pClass">
              <ul>
                <li>
                  {" "}
                  <a
                    style={{ boxShadow: `none` }}
                    target="_blank"
                    href={`https://angularhive.com/free-complete-angular-tutorial/`}
                    rel="noopener noreferrer"
                  >
                    The complete Angular Tutorial
                  </a>{" "}
                  (In progress)
                </li>
                <li>
                  {" "}
                  <a
                    style={{ boxShadow: `none` }}
                    target="_blank"
                    href={`https://angularhive.com/how-to-use-template-reference-variable-to-interact-with-child-component/`}
                    rel="noopener noreferrer"
                  >
                    Components interaction using Template Reference Variable
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    style={{ boxShadow: `none` }}
                    target="_blank"
                    href={
                      "https://angularhive.com/a-complete-step-by-step-guide-to-develop-pwa-in-angular/"
                    }
                    rel="noopener noreferrer"
                  >
                    How to develop PWA app in Angular?
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <Link to="/blog/">
            <Button marginTop="35px" marginBottom="15px">
              Go to Blog
            </Button>
          </Link>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
